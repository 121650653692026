
import layout from '@/layout'
// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/libs/util.import.' + process.env.NODE_ENV)

/**
 * 在主框架内显示
 */
const frameIn = [
  {
    path: '/',
    redirect: { name: 'index' },
    component: layout,
    children: [
      // 首页
      {
        path: '/index',
        name: 'index',
        meta: {
          keepAlive: false
        },
        component: _import('page/home')
      },
      // 开发文档
      {
        path: '/document',
        name: 'document',
        meta: {
          keepAlive: false
        },
        component: _import('page/document')
      }
    ]
  }
]

/**
 * 错误页面
 */
const errorPage = [
  {
    path: '*',
    name: '404',
    component: _import('error/404')
  }
]

// 重新组织后导出
export default [
  ...frameIn,
  ...errorPage
]
