import Vue from 'vue'
import VueRouter from 'vue-router'
import util from '@/libs/util'
// 进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// 固定菜单与路由
import routes from '@/router/routes'
// 路由与组件映射关系

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return VueRouterPush.call(this, location).catch(err => err)
}
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return VueRouterReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
// 导出路由 在 main.js 里使用
const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: [...routes]
})

// 免校验token白名单
const whiteList = ['/index']

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach((to, from, next) => {
  // 进度条
  NProgress.start()
  // 关闭搜索面板
  next()
})

router.afterEach(to => {
  // 进度条
  NProgress.done()

  // 更改标题
  util.title(to.meta.title)
})

export default router
