import Cookies from 'js-cookie'

const cookies = {}

/**
 * @description 存储 cookie 值
 * @param {String} name cookie name
 * @param {String} value cookie value
 * @param {Object} setting cookie setting
 */
cookies.set = function(name = 'default', value = '', cookieSetting = {}) {
  const currentCookieSetting = {
    expires: 1,
    domain: ['localhost', '127.0.0.1', '192.168.3.24'].includes(document.domain) ? document.domain : '.solot.co'
  }
  Object.assign(currentCookieSetting, cookieSetting)
  // Cookies.set(`openapi-${process.env.VUE_APP_VERSION}-${name}`, value, currentCookieSetting)
  Cookies.set(`openapi-${name}`, value, currentCookieSetting)
}

/**
 * @description 拿到 cookie 值
 * @param {String} name cookie name
 */
cookies.get = function(name = 'default') {
  return Cookies.get(`openapi-${name}`)
}

/**
 * @description 拿到 cookie 全部的值
 */
cookies.getAll = function() {
  return Cookies.get()
}

/**
 * @description 删除 cookie
 * @param {String} name cookie name
 */
 cookies.remove = function(name = 'default') {
  const config = {
    domain: ['localhost', '127.0.0.1', '192.168.3.24'].includes(document.domain) ? document.domain : '.solot.co'
  }
  return Cookies.remove(`openapi-${name}`, config)
}

export default cookies
