import cookies from './util.cookies'

import Momemt from 'moment'
Momemt.locale('zh-cn')

const util = {
  cookies,
}

/**
 * @description 更新标题
 * @param {String} title 标题
 */
util.title = function (titleText) {
  const processTitle = process.env.VUE_APP_TITLE || 'D2Admin'
  window.document.title = `${processTitle}${titleText ? ` | ${titleText}` : ''}`
}

/**
 * @description 打开新页面
 * @param {String} url 地址
 */
util.open = function (url) {
  var a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  a.setAttribute('id', 'd2admin-link-temp')
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(document.getElementById('d2admin-link-temp'))
}

util.formatTimestamp = function (timestamp, format = 'yyyy-MM-DD HH:mm:ss') {
  if (timestamp) {
    return Momemt(timestamp * 1000).format(format)
  }
}

util.toLogin = function () {
  window.location.href = `${process.env.VUE_APP_DOMAIN_URL}/login?client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_REDIRECT_URI}`
}

export default util
