
export default {
  components: {

  },
  data() {
    return {
    }
  },
  methods: {
    // visible 重置
    setVisibleFalse() {
      for (const key in this.visible) {
        this.visible[key] = false
      }
    },
    // 跳转到目标页
    routerToPage(path, query) {
      query ? this.$router.push({ path: path, query: query }) : this.$router.push(path)
    },
    // 路由返回
    routerBack() {
      this.$router.back()
    },
    // 克隆
    clone(data) {
      return JSON.parse(JSON.stringify(data))
    },
    // 搜索
    search() {
      this.$refs.dataTable.loadTableData()
    },
    // 重置
    reset() {
      this.$refs.dataTable.loadTableData('reload')
    },
    // 关闭dialog
    closeDialog(value) {
      this.setVisibleFalse()
      if (value) {
        this.$refs.dataTable.loadTableData()
      }
    },
    tableColumnEmpty(row, column, cellValue) {
      if (!cellValue) { return '—' }
      return cellValue
    },
    tableTimeFormat(row, column, cellValue) {
      if (!cellValue) { return '—' }
      return this.$moment(cellValue).format('YYYY-MM-DD HH:mm:ss')
    },
    clearAllValueObj(val) {
      for (const key in val) {
        val[key] = ''
      }
    }
  }
}
