import util from '@/libs/util.js'
import api from '@/api'
export default {
  namespaced: true,
  state: { info: {} },
  actions: {
    /**
    * @description 检测是否登录
    */
    async getCheck({ commit, dispatch }) {
      const resA = await api.SYS_CHECK()
      await dispatch('checkInfo')
      return resA
    },
    async checkInfo({ commit, dispatch }) {
      const access_token = util.cookies.get('token')
      const res = await api.SYS_USER_ME({ access_token: access_token })
      // 设置 cookie 一定要存 uuid 和 token 两个 cookie
      // 整个系统依赖这两个数据进行校验和存储
      // uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
      // token 代表用户当前登录状态 建议在网络请求中携带 token
      // 如有必要 token 需要定时更新，默认保存一天
      util.cookies.set('uuid', res.sub)
      await commit('setInfo', res)
    },
    async getInfo({ commit, dispatch }, {
      code = ''
    } = {}) {
      const formData = {
        grant_type: 'authorization_code',
        code: code,
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: '353e59cf7f19def220ab0e90d89c1c77',
        redirect_uri: process.env.VUE_APP_REDIRECT_URI
      }
      const resD = await api.SYS_USER_TOKEN(formData)
      const res = await api.SYS_USER_ME({ access_token: resD.access_token })
      // 设置 cookie 一定要存 uuid 和 token 两个 cookie
      // 整个系统依赖这两个数据进行校验和存储
      // uuid 是用户身份唯一标识 用户注册的时候确定 并且不可改变 不可重复
      // token 代表用户当前登录状态 建议在网络请求中携带 token
      // 如有必要 token 需要定时更新，默认保存一天
      util.cookies.set('uuid', res.sub)
      util.cookies.set('token', resD.access_token)
      await commit('setInfo', res)
      // 用户登录后从持久化数据加载一系列的设置
      return true
    },
    /**
    * @description 注销用户并返回登录页面
    * @param {Object} context
    * @param {Object} payload confirm {Boolean} 是否需要确认
    */
    async logout({ commit, dispatch }) {
      /**
       * @description 注销
       */
      const res = await api.SYS_LOGINOUT({ uuid: util.cookies.get('uuid') })
      if (res && res.xsrf) {
        await api.SYS_LOGINOUT_CONFIRM({ xsrf: res.xsrf, logout: 'yes' })
      }
      // 清空 vuex 用户信息
      await dispatch('clear')
    },
    /**
    * 清除
    */
    async clear({ commit, dispatch }) {
      // 删除cookie
      util.cookies.remove('token')
      util.cookies.remove('uuid')
      // 清空 vuex 用户信息
      await commit('setInfo', {})
    }
  },

  mutations: {
    /**
     * @description 设置登录的用户信息
     * @param {Object} codeUrl
     */
    setInfo(state, info) {
      state.info = info
    }

  }
}
