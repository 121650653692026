// Vue
import Vue from 'vue'
import i18n from './i18n'
import App from './App'
// store
import store from '@/store/index'
import '@/assets/style/common.css'
import mixin from './mixins/base'

// 菜单和路由设置
import router from './router'

Vue.mixin(mixin)


new Vue({
  router,
  store,
  i18n,
  created() {
  },
  mounted() {
  },
  render: h => h(App)
}).$mount('#app')
