import qs from 'qs'
export default ({ request }) => ({

  /**
  * @description 是否登录
  */
  SYS_CHECK() {
    // 接口请求
    return request({
      url: '/session/check',
      method: 'get'
    })
  },

  /** 
  * @description 获取token / 重置token
  * @param {Object} data code:【获取authorize接口取authorization_code】  grant_type:authorization_code   获取token
  * @param {Object} data refresh_token:【取到token时得到的refresh_token】  grant_type:refresh_token   重置token
  */
  SYS_USER_TOKEN(data = {}) {
    // 接口请求
    return request({
      url: '/v1/user/token',
      method: 'post',
      // data
      data: qs.stringify(data),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
  },

  /**
   * @description 获取用户信息
   * @param {Object} data access_token:token
   */
  SYS_USER_ME(data = {}) {
    // 接口请求
    return request({
      url: '/v1/user/me',
      method: 'post',
      // data
      data: qs.stringify(data),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
  },
  /**
 * @description loginout 退出
 */
  SYS_LOGINOUT() {
    // 接口请求
    return request({
      url: '/session/end',
      method: 'get'
    })
  },

  /**
   * @description loginoutconfirm 退出确认
   * @param {Object} data xsrf:xxxx logout:yes
   */
  SYS_LOGINOUT_CONFIRM(data = {}) {
    // 接口请求
    return request({
      url: '/session/end/confirm',
      method: 'post',
      data: qs.stringify(data),
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
  }

})
